import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const HomeHero = ({ className, children }) => (
    <StaticQuery 
        query={graphql`
            {
                homeHero: file(relativePath: { eq: "groomed-track-bg.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 3000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                homeHeroTrack: file(relativePath: { eq: "racecourse.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 3000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}

    render={ data => {
        const imageData = data.homeHeroTrack.childImageSharp.fluid

        return <BackgroundImage
                tag="section"
                className={className}
                fluid={imageData}
                backgroundColor={`#85b7fe`}
                >
                    <div style={{
                       background: '#7CB3FE75',
                       overflow: 'hidden',
                       height: '100%',
                       zIndex: 2  
                    }}>
                        { children }
                    </div>
                </BackgroundImage>
    }}
    />
)

const StyledBackgroundSection = styled(HomeHero)`
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 900px;
  overflow: hidden;
`
export default StyledBackgroundSection
import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import HomeLayout from "../components/HomeLayout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


import HomeHero from "../components/HomeHero/HomeHero"
import HomeHeader from "../components/Header/HomeHeader"
import Tetons from "../images/Tetons"
import SnowConditions from "../components/SnowConditions/SnowConditions"
import Partners from "../components/PartnersSponsors/Partners"
import Sponsors from "../components/PartnersSponsors/Sponsors"
import MadshusLogo from "../images/madshus-logo"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const IndexPage = ({ data }) => {
  
  const homepageTextSection = data.homeText
  console.log(data.homeText.json)

  return (
    <HomeLayout>
      <SEO title="Home" />
      <HomeHero>
        <HomeHeader />
        {/* <div className="hero-container">        
          <div className="hero-content">             
            <div style={{
              alignSelf: 'center',
              width: '100%'
            }}> */}
        <Container>
          <Container style={{
            marginTop: '10%'
          }}>
          <Row>
            <Col>
            <h1 className="hero">Teton Ridge Classic</h1>
            <p style={{textAlign: 'center', color: 'white', marginBottom: 0}}>Presented By</p>
            <div style={{textAlign: 'center', marginBottom: '1rem'}}>
              <MadshusLogo fill="white" width="275px" />
            </div>

<h2 className="hero">21st Annual - January 6, 2024</h2>
<div className="my-4 text-center">
  <Link to="/registration">
  <Button className="btn-lg hero-button">Register Now</Button></Link>
  </div>
            </Col>
          </Row>
          </Container>
          
          
            <div className="teton-overlay-home">
              <div>
                <Tetons />
              </div>
            </div>
          
        </Container>
      </HomeHero>

      <Container fluid className="my-4">
        <Row className="my-4">
          <Col className="text-center">
            <h1>
              Classic skiing at its finest!
            </h1>
            
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm={8}>
            <Row>
              <Col>
                <h3>Latest News &amp; Updates</h3>
                {/* <div
                  className="float-right"
                  style={{
                    width: "350px",
                    heigth: "auto",
                    marginLeft: "10px",
                  }}
                >
                  <Img
                    fluid={data.skiersRacing.childImageSharp.fluid}
                    alt="Skiers racing the Teton Ridge Classic"
                    className="rounded"
                  />
                </div> */}
                
                {documentToReactComponents(
                  homepageTextSection
                    .json,
                    options
                )}
                
                <Img
                  fluid={data.skiers.childImageSharp.fluid}
                  alt="Skiers racing the Teton Ridge Classic"
                  className="rounded"
                />
              </Col>
            </Row>            
          </Col>
          <Col sm={4}>
            <SnowConditions />
          </Col>
        </Row>
        <hr />
        <Row>
              <Partners />
            </Row>
            <hr />
            <Row>
              <Sponsors />
            </Row>
      </Container>
    </HomeLayout>
  )
}

export const query = graphql`
  query {
    homeText:contentfulFrontPageNewsUpdatesNewsUpdatesRichTextNode {
      json
    }
    skiersRacing: file(relativePath: { eq: "IMG_2762.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skiers: file(relativePath: { eq: "trc-racers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 80){
          ...GatsbyImageSharpFluid
        }
      }
    }
    raceCourse: file(relativePath: { eq: "racecourse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage

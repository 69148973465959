import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSnowflakes, faAngleRight } from "@fortawesome/pro-regular-svg-icons"

import { Container, Row, Col, Button } from "react-bootstrap"
import WeatherFetch from "./WeatherFetch"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const SnowConditions = () => {
  const data = useStaticQuery(
    graphql`
      query {
        snowUpdates: contentfulSnowAndWeatherUpdatesContentRichTextNode {
          json
        }
      }
    `
  )

  const snowUpdatesSection = data.snowUpdates

  console.log(data)

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<div style="width: auto; float: right; clear: both; ">
            <img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="margin: 0px" />
            <p style="text-align: center">
              <em>${fields.title["en-US"]}</em>
            </p>
            </div>`,
          }}
        />
      ),

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields.imageLink
        const imgLnk = node.data.target.fields.url
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}" style="width: 100%;" /></a>`,
            }}
          />
        )
      },

      // Embed iframe content
    //   [INLINES.EMBEDDED_ENTRY]: (node) => {
    //     const embed = node.data.target.fields.markdownContent;
    //     return (
    //         <div dangerouslySetInnerHTML={{__html: `${embed['en-US']}`}} />
    //     )
    // }
    },
  }

  return (
    <Container>
      <Row
        className="mb-4 text-center p-4"
        style={{
          backgroundColor: "#85b7fe",
          borderRadius: "10px",
        }}
      >
        <Col>
          <h3 className="title">
            Snow Conditions <FontAwesomeIcon icon={faSnowflakes} />
          </h3>
          {/* <p className="updated">Updated: January 3, 2020</p> */}
          <WeatherFetch />
          <p>
          {documentToReactComponents(
                    snowUpdatesSection
                      .json,
                    options
                  )}
          </p>
          <a href="https://forecast.weather.gov/MapClick.php?lon=-111.06911659240723&lat=43.55671149558472" target="_blank" rel="noopener noreferrer"><Button>
            More Weather{" "}
            <span>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Button>
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default SnowConditions

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import HomeHeader from "./Header/HomeHeader"
import Footer from "./Footer/Footer"
import "./layout.css"

const HomeLayout = ({ children }) => {
  return (
    <div className="site">
      {/* <HomeHeader /> */}
      
      <main className="site-content">
        <div>
          { children }
        </div>
      </main>      
    
    <Footer />  
    </div>
  )
}

export default HomeLayout
